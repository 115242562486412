import React, {Component} from 'react';
import {Timeline, TimelineElement, TimelineElementImage, TimelineElementPanel} from '../Components/Timeline/Timeline';

import img1 from './Images/1.jpg';
import img2 from './Images/2.jpg';
import img3 from './Images/3.jpg';
import img4 from './Images/4.jpg';

export default class Volunteering extends Component {
  render() {
    return (
      <Timeline>
        <TimelineElement inverted={false} href="https://tgb-darmstadt.net/volleyball/">
          <TimelineElementImage src={img4} alt="TGB Darmstadt" />
          <TimelineElementPanel time="Since December 2023" name={'TGB 1865 Darmstadt e.V.'}>
            <p className="text-muted">
              {/* TODO change 2 time to past */}
              In December 2023, I was elected as the deputy head of the volleyball department and am therefore a member of the TGB board.
              <br />
              I am also deputy team captain of the mixed team for the 2023/24 season.
            </p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://jugendarbeit.landkreis-miltenberg.de/allgemeines/veranstaltungen-und-freizeiten/abenteuerspielplatz-in-elsenfeld/">
          <TimelineElementImage src={img2} alt="ASP" />
          <TimelineElementPanel time="August 2024" name={'Adventure youth playground in Elsenfeld'}>
            <p className="text-muted">
            As part of the organization team, I was jointly responsible for the youth adventure playground in Elsenfeld organized by the Miltenberg regional council.
            During the school vacations, we built objects and huts out of wood with around 70 children.
            </p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={false} href="https://ministranten.bistum-wuerzburg.de">
          <TimelineElementImage src={img3} alt="Minis Würzburg" />
          <TimelineElementPanel time="July - August 2024" name={'International Pilgrimage Bus Leader'}>
            <p className="text-muted">
            I was part of the international Rome pilgrimage of the acolytes in the <a href='https://ministranten.bistum-wuerzburg.de' target='_blank' rel="noopener noreferrer">Diocese of Würzburg</a> as a bus leader.
            In this role, I took care of the planning and implementation of the travel program, the organization on the bus, communication with the hotel and much more for almost 50 people.
            </p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://jugendarbeit.landkreis-miltenberg.de/allgemeines/veranstaltungen-und-freizeiten/abenteuerspielplatz-in-erlenbach/">
          <TimelineElementImage src={img2} alt="ASP" />
          <TimelineElementPanel time="August 2023" name={'Adventure youth playground in Erlenbach am Main'}>
            <p className="text-muted">
              As part of the organisation team, I was jointly responsible for the adventure youth playground in Erlenbach am Main.
              Organized by the Miltenberg regional council, we built objects and huts out of wood together with around 100 children during the school vacations.
            </p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={false} href="https://www.minis-lioba.de">
          <TimelineElementImage src={img1} alt="Minis St. Lioba" />
          <TimelineElementPanel time="August 2006 - February 2022" name={'Minis St. Lioba'}>
            <p className="text-muted">
              I am part of the Minis since 2006. In November 2013 I became a youth leader and started panning events, weekly group meetings and more.
              <br />
              During my time as a leader I have planned events, hosted weekly group sessions, <a href={`${process.env.PUBLIC_URL}/identity#portfolio/minis-webapp`}>developed a internal planning app, created a new web presence</a>, worked as treasurer, and did so much more.
            </p>
          </TimelineElementPanel>
        </TimelineElement>
      </Timeline>
    );
  }
}
